@charset "UTF-8";
@import "~@ng-select/ng-select/themes/default.theme.css";
.grid {
  width: calc(100% - 54px);
  max-width: 1232px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .grid {
    width: calc(100% - 110px);
  }
}
@media (min-width: 1024px) {
  .grid {
    width: calc(100% - 74px);
  }
}
@media (min-width: 1440px) {
  .grid {
    width: calc(100% - 208px);
  }
}

.col-1 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-1 {
    width: 8.3333333333%;
  }
}
.col-2 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-2 {
    width: 16.6666666667%;
  }
}
.col-3 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-3 {
    width: 25%;
  }
}
.col-4 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-4 {
    width: 33.3333333333%;
  }
}
.col-5 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-5 {
    width: 41.6666666667%;
  }
}
.col-6 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-6 {
    width: 50%;
  }
}
.col-7 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-7 {
    width: 58.3333333333%;
  }
}
.col-8 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-8 {
    width: 66.6666666667%;
  }
}
.col-9 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-9 {
    width: 75%;
  }
}
.col-10 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-10 {
    width: 83.3333333333%;
  }
}
.col-11 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-11 {
    width: 91.6666666667%;
  }
}
.col-12 {
  width: 100%;
}
@media (min-width: 1024px) {
  .col-12 {
    width: 100%;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.dd-animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

.reset-button, .dd-m-modal__close, .dd-m-modal-nav__item, .dd-m-slider-banner__arrow, .dd-m-btn, .btn,
app-button {
  -webkit-appearance: none !important;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
.reset-button:not(:disabled):not(.disabled), .dd-m-modal__close:not(:disabled):not(.disabled), .dd-m-modal-nav__item:not(:disabled):not(.disabled), .dd-m-slider-banner__arrow:not(:disabled):not(.disabled), .dd-m-btn:not(:disabled):not(.disabled), .btn:not(:disabled):not(.disabled),
app-button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.reset-input, .dd-m-form-control__checkbox, .dd-m-form-control__radio, .ng-select-container .ng-input input, .ng-select-container {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background-clip: padding-box;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
}
.reset-input:disabled, .dd-m-form-control__checkbox:disabled, .dd-m-form-control__radio:disabled, .ng-select-container .ng-input input:disabled, .ng-select-container:disabled, .reset-input.disabled, .disabled.dd-m-form-control__checkbox, .disabled.dd-m-form-control__radio, .ng-select-container .ng-input input.disabled, .disabled.ng-select-container {
  cursor: default;
}

.reset-list {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  color: inherit;
  -webkit-appearance: none;
  background-color: transparent;
  font: inherit;
  vertical-align: baseline;
  list-style-type: none;
}
.reset-list a {
  color: inherit;
  text-decoration: none;
}
.reset-list li {
  list-style-type: none;
}

@font-face {
  font-family: "icomoon";
  src: url("assets/fonts/icomoon/icomoon.eot?jtfjl3");
  src: url("assets/fonts/icomoon/icomoon.eot?jtfjl3#iefix") format("embedded-opentype"), url("assets/fonts/icomoon/icomoon.ttf?jtfjl3") format("truetype"), url("assets/fonts/icomoon/icomoon.woff?jtfjl3") format("woff"), url("assets/fonts/icomoon/icomoon.svg?jtfjl3#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.dd-icon,
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clap-full .path1:before {
  content: "";
  color: #91ff00;
}

.icon-clap-full .path2:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path3:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path4:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path5:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path6:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path7:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path8:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path9:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path10:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path11:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path12:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path13:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path14:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path15:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path16:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path17:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path18:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path19:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path20:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path21:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path22:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-clap-full .path23:before {
  content: "";
  margin-left: -1.0625em;
  color: #000d4c;
}

.icon-spinner:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-dots:before {
  content: "";
}

.icon-clap:before {
  content: "";
}

.icon-balloon:before {
  content: "";
}

.icon-send:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-whatsapp:before {
  content: "";
}

.icon-anchor:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-warning:before {
  content: "";
}

.icon-hands:before {
  content: "";
}

.icon-start:before {
  content: "";
}

.icon-in:before {
  content: "";
}

.icon-fb:before {
  content: "";
}

.icon-ig:before {
  content: "";
}

.icon-heart-full .path1:before {
  content: "";
  color: #91ff00;
}

.icon-heart-full .path2:before {
  content: "";
  margin-left: -1.181640625em;
  color: #000d4c;
}

.icon-heart-full .path3:before {
  content: "";
  margin-left: -1.181640625em;
  color: #000d4c;
}

.icon-minus-solid:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-more:before {
  content: "";
}

.icon-heart:before {
  content: "";
}

.icon-expand:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-top:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-pointer-right:before {
  content: "";
}

.icon-pointer-bottom:before {
  content: "";
}

.icon-pointer-top:before {
  content: "";
}

.icon-pointer-left:before {
  content: "";
}

.icon-close:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-health:before {
  content: "";
}

.icon-logo:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-woman:before {
  content: "";
}

@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Bold.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sanomatsans/SanomatSans-Bold.woff2") format("woff2"), url("/assets/fonts/sanomatsans/SanomatSans-Bold.woff") format("woff"), url("/assets/fonts/sanomatsans/SanomatSans-Bold.ttf") format("truetype"), url("/assets/fonts/sanomatsans/SanomatSans-Bold.svg#SanomatSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Regular.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sanomatsans/SanomatSans-Regular.woff2") format("woff2"), url("/assets/fonts/sanomatsans/SanomatSans-Regular.woff") format("woff"), url("/assets/fonts/sanomatsans/SanomatSans-Regular.ttf") format("truetype"), url("/assets/fonts/sanomatsans/SanomatSans-Regular.svg#SanomatSans-Regular") format("svg");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Medium.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sanomatsans/SanomatSans-Medium.woff2") format("woff2"), url("/assets/fonts/sanomatsans/SanomatSans-Medium.woff") format("woff"), url("/assets/fonts/sanomatsans/SanomatSans-Medium.ttf") format("truetype"), url("/assets/fonts/sanomatsans/SanomatSans-Medium.svg#SanomatSans-Medium") format("svg");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Black.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Black.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sanomatsans/SanomatSans-Black.woff2") format("woff2"), url("/assets/fonts/sanomatsans/SanomatSans-Black.woff") format("woff"), url("/assets/fonts/sanomatsans/SanomatSans-Black.ttf") format("truetype"), url("/assets/fonts/sanomatsans/SanomatSans-Black.svg#SanomatSans-Black") format("svg");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-Light.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sanomatsans/SanomatSans-Light.woff2") format("woff2"), url("/assets/fonts/sanomatsans/SanomatSans-Light.woff") format("woff"), url("/assets/fonts/sanomatsans/SanomatSans-Light.ttf") format("truetype"), url("/assets/fonts/sanomatsans/SanomatSans-Light.svg#SanomatSans-Light") format("svg");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sanomat Sans";
  src: url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.eot");
  src: url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.woff2") format("woff2"), url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.woff") format("woff"), url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.ttf") format("truetype"), url("/assets/fonts/sanomatsans/SanomatSans-RegularItalic.svg#SanomatSans-RegularItalic") format("svg");
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}
* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  font-family: "Sanomat Sans";
  font-size: 1rem;
  line-height: normal;
  color: #010f65;
  overflow-x: hidden;
  overflow-anchor: none;
}
body.overlay {
  overflow: hidden;
}

a {
  text-decoration: none;
  transition: 0.2s all ease;
}

.main {
  flex: 1;
  margin-top: 8.25rem;
}
@media (min-width: 768px) {
  .main {
    margin-top: 9.375rem;
  }
}
@media (min-width: 1024px) {
  .main {
    margin-top: 9.9375rem;
  }
}

[hidden],
.dd-hide {
  display: none !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(255, 255, 255, 0.8);
}

.dd-b-hide-mobile {
  display: none;
}
@media (min-width: 768px) {
  .dd-b-hide-mobile {
    display: block;
  }
}
@media (min-width: 768px) {
  .dd-b-hide-mobile.inline {
    display: inline;
  }
}

@media (min-width: 768px) {
  .dd-b-hide-desk {
    display: none;
  }
}

.swiper-lazy:not(.swiper-lazy-loaded) {
  opacity: 0;
  transition: 1.4s all ease;
}

.swiper-lazy.swiper-lazy-loaded {
  opacity: 1;
  transition: 1.4s all ease;
}

mark {
  cursor: pointer;
  background: #61e7fc;
}

p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
ul::selection,
li::selection {
  background: #61e7fc;
}

app-button[width=full] {
  width: 100%;
}

.dd-m-btn {
  text-transform: uppercase;
  border-radius: 0.125rem;
  letter-spacing: 0.0625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: 0.2s all ease;
}
@media (min-width: 768px) {
  .dd-m-btn {
    letter-spacing: 0.073125rem;
  }
}
.dd-m-btn.outlined {
  border: 0.0625rem solid currentColor;
}
.dd-m-btn.filled {
  color: #fff;
}
.dd-m-btn:focus {
  outline: none;
  box-shadow: none;
}
.dd-m-btn.auto {
  width: auto;
}
.dd-m-btn.full {
  width: 100%;
}
.dd-m-btn.sm {
  height: 2rem;
  min-width: 2rem;
  padding: 0 0.75rem;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .dd-m-btn.sm {
    padding: 0 1.6875rem;
    height: 2.125rem;
    min-width: 2.125rem;
  }
}
.dd-m-btn.md {
  height: 2.5rem;
  min-width: 2.5rem;
  padding: 0 1.1875rem;
  font-size: 0.75rem;
}
@media (min-width: 768px) {
  .dd-m-btn.md {
    height: 2.875rem;
    min-width: 2.875rem;
    padding: 0 0.875rem;
    font-size: 0.875rem;
  }
}
.dd-m-btn.lg {
  height: 2.875rem;
  min-width: 2.875rem;
  padding: 0 0.875rem;
}
.dd-m-btn.xlg {
  height: 3.375rem;
  min-width: 3.375rem;
  padding: 0 0.875rem;
  font-size: 0.75rem;
  line-height: 0.9375rem;
}
@media (min-width: 768px) {
  .dd-m-btn.xlg {
    font-size: 0.875rem;
  }
}
.dd-m-btn.positive-dark.text {
  color: #010f65;
}
.dd-m-btn.positive-dark.filled {
  background-color: #010f65;
}
.dd-m-btn.positive-dark.filled:hover {
  background-color: #010b4c;
}
.dd-m-btn.positive-dark.outlined {
  color: #010f65;
}
.dd-m-btn.positive-light.text {
  color: #61e7fc;
}
.dd-m-btn.positive-light.filled {
  color: #010f65;
  background-color: #61e7fc;
}
.dd-m-btn.positive-light.filled:hover {
  background-color: #2fdffb;
}
.dd-m-btn.positive-light.outlined {
  color: #010f65;
  border-color: #61e7fc;
}
.dd-m-btn.energized-light.text {
  color: #92ff00;
}
.dd-m-btn.energized-light.filled {
  color: #010f65;
  background-color: #92ff00;
}
.dd-m-btn.energized-light.filled:hover {
  background-color: #75cc00;
}
.dd-m-btn.energized-light.outlined {
  color: #010f65;
  border-color: #92ff00;
}
.dd-m-btn.facebook.filled {
  background-color: #3e99ef;
}
.dd-m-btn.facebook.filled:hover {
  background-color: #3b5998;
}
.dd-m-btn.google.filled {
  background-color: #eb4031;
}
.dd-m-btn.google.filled:hover {
  background-color: #dd4b39;
}

.dd-m-menu {
  display: flex;
}
.dd-m-menu__item {
  text-decoration: none;
}

.form-search .btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.25rem;
}
@media (min-width: 768px) {
  .form-search .btn {
    right: 0.375rem;
  }
}

.dd-m-form-block .btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.25rem;
}
@media (min-width: 768px) {
  .dd-m-form-block .btn {
    right: 0.375rem;
  }
}

.form-help .form-title {
  margin: 0;
  margin-bottom: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.04375rem;
}
@media (min-width: 768px) {
  .form-help .form-title {
    margin-bottom: 1.0625rem;
    font-size: 1.1875rem;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 8.75rem !important;
}

.ng-select-container {
  position: relative;
  width: 100%;
  border: 0.0625rem solid !important;
  border-color: currentColor !important;
  font-size: 1rem;
  color: currentColor !important;
  border-radius: 0.125rem !important;
  background-color: transparent !important;
  background-image: url("/assets/img/icons/select-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center right 16px !important;
  box-shadow: none !important;
}
.ng-select-container .ng-placeholder {
  position: absolute !important;
  left: 1rem !important;
  display: block !important;
  color: currentColor !important;
  top: 50% !important;
  padding: 0 !important;
  transform: translateY(-50%);
  transition: 0.2s all ease;
}
.ng-select-container .ng-input {
  top: 0 !important;
  height: 100%;
  padding-left: 0 !important;
}
.ng-select-container .ng-input input {
  height: 100%;
}

.ng-select .ng-clear-wrapper {
  display: none !important;
}

.ng-value-container {
  padding-left: 0 !important;
}

.ng-option {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-size: 1rem;
  color: #010f65;
  font-weight: 300;
}

.ng-dropdown-panel {
  border-color: #010f65 !important;
}

.ng-arrow-wrapper {
  display: none;
}

.ng-option-label {
  color: #010f65 !important;
  font-weight: 300 !important;
}

.ng-option-marked:not(.ng-option-selected) {
  background-color: #fff !important;
}
.ng-option-marked.ng-option-selected {
  color: #010f65 !important;
}

.ng-select.ng-select-opened > .ng-select-container {
  background-color: transparent !important;
}

.dd-m-form-generic-error {
  display: block;
  margin: 0;
  margin-bottom: 1.25rem;
  padding: 0.375rem 1rem;
  font-size: 0.625rem;
  line-height: 16px;
  background-color: #eb4031;
  color: #fff;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .dd-m-form-generic-error {
    padding: 1rem;
    font-size: 0.875rem;
  }
}

.dd-m-form .swiper-container,
.dd-m-form .swiper-slide {
  overflow: initial !important;
}
.dd-m-form .swiper-slide {
  transition: 0.2s opacity ease;
}
.dd-m-form .swiper-slide:not(.swiper-slide-active) {
  opacity: 0;
}
.dd-m-form-title {
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.125rem;
}
@media (min-width: 768px) {
  .dd-m-form-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
.dd-m-form-row:not(:last-child) {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .dd-m-form-row:not(:last-child) {
    margin-bottom: 1.6875rem;
  }
}
.dd-m-form-control {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #010f65;
}
.dd-m-form-control:not(:last-child) {
  margin-bottom: 1.25rem;
}
.dd-m-form-control__wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.dd-m-form-control p {
  display: none;
}
.dd-m-form-control strong {
  display: block;
  margin-bottom: 0.875rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .dd-m-form-control strong {
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
.dd-m-form-control.error {
  color: #eb4031 !important;
}
.dd-m-form-control.error p {
  display: block;
  margin: 0;
  margin-bottom: 1.25rem;
  padding: 0.375rem 1rem;
  font-size: 0.625rem;
  line-height: 16px;
  background-color: #eb4031;
  color: #fff;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .dd-m-form-control.error p {
    padding: 1rem;
    font-size: 0.875rem;
  }
}
.dd-m-form-control.error input,
.dd-m-form-control.error textarea,
.dd-m-form-control.error app-input-touch-spin label,
.dd-m-form-control.error app-input-touch-spin button,
.dd-m-form-control.error app-select-multiple .ng-placeholder,
.dd-m-form-control.error app-select-multiple label,
.dd-m-form-control.error app-select-button .ng-dropdown-panel,
.dd-m-form-control.error app-select-button .ng-option-label,
.dd-m-form-control.error app-select-button .ng-value-label,
.dd-m-form-control.error app-select-multiple .ng-value-label,
.dd-m-form-control.error app-select-multiple .ng-dropdown-panel,
.dd-m-form-control.error .input-touch {
  color: #eb4031 !important;
  border-color: #eb4031 !important;
}
.dd-m-form-control.error app-select-button .ng-select-container:before,
.dd-m-form-control.error app-select-multiple .ng-select-container:before,
.dd-m-form-control.error app-input-touch-spin button {
  background: rgba(255, 0, 0, 0.1);
}
.dd-m-form-control__label {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .dd-m-form-control__label {
    font-size: 1rem;
  }
}
.dd-m-form-control__select input {
  padding-left: 1rem !important;
}
.dd-m-form-control__select.ng-select-filtered .ng-placeholder {
  display: none !important;
}
.dd-m-form-control__select.ng-select-multiple .ng-value-container .ng-star-inserted {
  display: none !important;
}
.dd-m-form-control__select.ng-select-multiple .ng-clear-wrapper {
  display: none !important;
}
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-opened .ng-placeholder,
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-container.ng-has-value .ng-placeholder {
  top: 1rem !important;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 300;
}
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-opened .ng-input,
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-opened .ng-value,
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-container.ng-has-value .ng-input,
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-container.ng-has-value .ng-value {
  display: block !important;
  padding: 10px 0 0 !important;
}
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-opened .ng-value,
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-container.ng-has-value .ng-value {
  padding-left: 1rem !important;
}
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-opened .ng-value-icon,
.dd-m-form-control__select:not(.ng-select-multiple) .ng-select-container.ng-has-value .ng-value-icon {
  display: none !important;
}
.dd-m-form-control__select.sm .ng-select-container {
  height: 2.5rem;
}
@media (min-width: 768px) {
  .dd-m-form-control__select.sm .ng-select-container {
    height: 2.875rem;
  }
}
.dd-m-form-control__select.lg .ng-select-container {
  height: 3.375rem;
}
.dd-m-form-control__select.error {
  border-color: #eb4031;
}
.dd-m-form-control__radio {
  width: 1.4375rem;
  height: 1.4375rem;
  border-radius: 50%;
  box-shadow: 0 0 0 0.0625rem #010f65;
}
.dd-m-form-control__radio:checked {
  background-color: #92ff00;
}
.dd-m-form-control__checkbox {
  width: 1.4375rem;
  height: 1.4375rem;
  border-radius: 0.125rem;
  box-shadow: inset 0 0 0 0.0625rem #010f65;
}
.dd-m-form-control__checkbox:checked {
  background-color: #92ff00;
}
.dd-m-form app-radio {
  display: block;
}
.dd-m-form app-radio:not(:last-child) {
  margin-bottom: 0.75rem;
}

app-input,
app-select,
app-select-gender,
app-select-blood-type,
app-select-state,
app-select-city,
app-select-pregnant-weeks {
  position: relative;
  display: block;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: #fff;
  color: #010f65;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.card .favorite-icon {
  cursor: pointer;
  font-size: 22px;
  position: absolute;
  top: 26px;
  right: 26px;
  z-index: 1;
}
@media (min-width: 768px) {
  .card .favorite-icon {
    right: 1.9375rem;
    top: 1.25rem;
  }
}
.card.lock .card-figure:after {
  content: "";
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  bottom: 0;
  right: 0;
  background-color: rgba(1, 15, 101, 0.8);
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/assets/img/icons/restrito-paciente.svg");
  background-size: 24px;
}
.card.lock .card-figure.professionals:after {
  background-image: url("/assets/img/icons/restrito-profissionais.svg");
  background-size: 24px;
}
.card-figure {
  position: relative;
  margin: 0;
}
.card-figure img {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  width: auto;
  height: auto;
  display: block;
}
.card-figure--sm {
  min-height: auto;
  max-height: none;
  height: auto;
  overflow: visible;
  padding: 1.6875rem 1.6875rem 0 1.6875rem;
  display: block;
  text-align: center;
}
@media (min-width: 376px) {
  .card-figure--sm {
    display: flex;
    min-height: 9.625rem;
    max-height: 9.625rem;
  }
}
@media (min-width: 768px) {
  .card-figure--sm {
    min-height: 12.625rem;
    max-height: 12.625rem;
  }
}
@media (min-width: 1024px) {
  .card-figure--sm {
    min-height: 10.5625rem;
    max-height: 10.5625rem;
  }
}
.card-figure--sm img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
@media (min-width: 376px) {
  .card-figure--sm img {
    width: auto;
    max-width: 100%;
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.6875rem;
}
.card-title {
  flex: 1;
  margin: 0;
  margin-bottom: 0.875rem;
  font-size: 0.875rem;
  line-height: 1.05rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .card-title {
    margin-bottom: 1.25rem;
    font-size: 1rem;
    line-height: 1.1375rem;
  }
}

.banner {
  position: relative;
}

app-banner-institutional {
  display: block;
}
@media (min-width: 1024px) {
  app-banner-institutional:nth-child(even) .banner-wrapper {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1024px) {
  app-banner-institutional:nth-child(even) .banner-figure {
    margin-left: 2.3125rem;
  }
}
@media (min-width: 1024px) {
  app-banner-institutional:nth-child(odd) .banner-figure {
    margin-right: 2.3125rem;
  }
}

.dd-l-wrapper, .dd-l-wrapper-inner, .slider-header, .dd-m-slider-banner.default .slider-header, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-cards .swiper-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.dd-l-wrapper-inner, .slider-header, .dd-m-slider-banner.default .slider-header, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-cards .swiper-container {
  max-width: calc(100% - 54px);
}
@media screen and (min-width: 768px) {
  .dd-l-wrapper-inner, .slider-header, .dd-m-slider-banner.default .slider-header, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-cards .swiper-container {
    max-width: calc(100% - 109px);
  }
}
@media screen and (min-width: 1024px) {
  .dd-l-wrapper-inner, .slider-header, .dd-m-slider-banner.default .slider-header, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-cards .swiper-container {
    max-width: calc(100% - 74px);
  }
}
@media screen and (min-width: 1440px) {
  .dd-l-wrapper-inner, .slider-header, .dd-m-slider-banner.default .slider-header, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-cards .swiper-container {
    max-width: 1366px;
  }
}

.dd-l-full {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.dd-l-page-calculator app-header,
.dd-l-page-calculator app-footer {
  display: none;
}
.dd-l-page-calculator main.main {
  margin-top: 0;
}

.dd-m-slider-cards {
  padding: 1.6875rem 0;
}
@media (min-width: 768px) {
  .dd-m-slider-cards {
    padding: 3.4375rem 0;
  }
}
@media (min-width: 1024px) {
  .dd-m-slider-cards {
    padding: 2.3125rem 0;
  }
}
@media (min-width: 1440px) {
  .dd-m-slider-cards {
    padding: 3.25rem 0;
  }
}
.dd-m-slider-cards-nav {
  display: flex;
  justify-content: center;
}
.dd-m-slider-cards-prev, .dd-m-slider-cards-next {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 4.875rem !important;
  height: 1.3125rem !important;
  padding: 0 0.875rem !important;
  background-color: #010f65 !important;
  color: #fff !important;
  display: block !important;
  font-size: 0.5rem !important;
  margin: 0 0.125rem !important;
  transition: 0.2s all ease !important;
  margin-top: 1.6875rem !important;
}
@media (min-width: 768px) {
  .dd-m-slider-cards-prev, .dd-m-slider-cards-next {
    width: 11.625rem !important;
    height: 1.625rem !important;
    margin-top: 3.4375rem !important;
  }
}
@media (min-width: 1024px) {
  .dd-m-slider-cards-prev, .dd-m-slider-cards-next {
    margin-top: 2.3125rem !important;
  }
}
@media (min-width: 1440px) {
  .dd-m-slider-cards-prev, .dd-m-slider-cards-next {
    margin-top: 3.25rem !important;
  }
}
.dd-m-slider-cards-prev:hover, .dd-m-slider-cards-next:hover {
  background-color: #010b4c !important;
}
.dd-m-slider-cards-prev:after, .dd-m-slider-cards-next:after {
  font-size: 0.5rem !important;
  line-height: 1.3125rem !important;
}
@media (min-width: 768px) {
  .dd-m-slider-cards-prev:after, .dd-m-slider-cards-next:after {
    line-height: 1.625rem !important;
  }
}
.dd-m-slider-cards-prev {
  text-align: left;
}
.dd-m-slider-cards-next {
  text-align: right;
}
.dd-m-slider-cards.stable {
  position: relative;
  background-color: #f9f9f6;
}
.dd-m-slider-cards.stable:before, .dd-m-slider-cards.stable:after {
  content: "";
  position: absolute;
  top: 0;
  display: block;
  width: 120rem;
  height: 100%;
  background-color: #f9f9f6;
}
.dd-m-slider-cards.stable:before {
  left: 50%;
}
.dd-m-slider-cards.stable:after {
  right: 50%;
}

.slider-gallery .swiper-wrapper {
  align-items: center;
  justify-content: center;
}
.slider-gallery .slider-header {
  margin-bottom: -0.4375rem;
}
@media (min-width: 768px) {
  .slider-gallery .slider-header {
    margin-bottom: -9px;
  }
}
@media (min-width: 1024px) {
  .slider-gallery .slider-header {
    margin-bottom: -6px;
  }
}
.slider-gallery .slider-header__title {
  font-weight: 300;
  line-height: 1rem;
}
.slider-gallery .slider-info p {
  font-size: 1rem;
  line-height: 1.125rem;
}
@media (min-width: 1024px) {
  .slider-gallery .slider-info p {
    margin-bottom: 1.1875rem;
  }
}
.slider-gallery .slider-info p > :first-child {
  margin-top: 0;
}

.dd-m-slider-banner figure {
  margin: 0;
}
.dd-m-slider-banner img {
  width: auto;
  height: auto;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
.dd-m-slider-banner + p > br {
  display: none;
}
.dd-m-slider-banner.default {
  position: relative;
  margin-top: 1.6875rem;
  margin-bottom: 1.5625rem;
}
.dd-m-slider-banner.default .dd-m-slider-banner__caption {
  margin-left: 1.3125rem;
}
@media (min-width: 768px) {
  .dd-m-slider-banner.default .dd-m-slider-banner__caption {
    margin-left: 2.75rem;
  }
}
@media (min-width: 1440px) {
  .dd-m-slider-banner.default .dd-m-slider-banner__caption {
    margin-left: 5.125rem;
  }
}
.dd-m-slider-banner.full {
  top: 0;
  left: 0;
  right: 0;
}
.dd-m-slider-banner.full figure {
  height: 100%;
}
.dd-m-slider-banner.full img {
  width: auto !important;
  height: auto !important;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 !important;
}
.dd-m-slider-banner .swiper-slide {
  margin: 0;
  overflow: hidden !important;
}
.dd-m-slider-banner .swiper-slide img {
  display: block;
  margin: 0;
  width: auto !important;
  height: auto !important;
}
.dd-m-slider-banner-next, .dd-m-slider-banner-prev {
  align-items: center !important;
  justify-items: center !important;
  position: absolute !important;
  bottom: 0 !important;
  top: inherit !important;
  z-index: 2 !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  background-color: rgba(1, 15, 101, 0.8) !important;
  color: #fff !important;
  font-size: 1.1875rem !important;
  transition: 0.2s all ease;
}
@media (min-width: 768px) {
  .dd-m-slider-banner-next, .dd-m-slider-banner-prev {
    width: 3.4375rem !important;
    height: 2.875rem !important;
  }
}
.dd-m-slider-banner-next:hover, .dd-m-slider-banner-prev:hover {
  background-color: #010f65;
}
.dd-m-slider-banner-next::after, .dd-m-slider-banner-prev::after {
  content: none !important;
}
.dd-m-slider-banner-next i, .dd-m-slider-banner-prev i {
  margin: auto;
}
.dd-m-slider-banner-next {
  right: 0 !important;
  left: inherit !important;
  padding-right: 0.375rem !important;
}
.dd-m-slider-banner-prev {
  padding-left: 0.375rem !important;
  right: 2.5rem !important;
  left: inherit !important;
}
@media (min-width: 768px) {
  .dd-m-slider-banner-prev {
    right: 3.4375rem !important;
  }
}
.dd-m-slider-banner__wrapper {
  max-width: 1366px;
  width: 100%;
  position: absolute !important;
  top: 6.5625rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}
@media (min-width: 768px) {
  .dd-m-slider-banner__wrapper {
    top: 6.9375rem;
  }
}
@media (min-width: 1024px) {
  .dd-m-slider-banner__wrapper {
    top: 5.8125rem;
  }
}
.dd-m-slider-banner__wrapper.only-title app-button-anchor,
.dd-m-slider-banner__wrapper.only-title app-button-back {
  display: none;
}
@media (max-width: 767px) {
  .dd-m-slider-banner__wrapper.only-title {
    top: 10rem;
  }
}
.dd-m-slider-banner__wrapper.only-image .dd-m-slider-banner__caption {
  display: none;
}
.dd-m-slider-banner__wrapper .dd-m-slider-banner__caption {
  max-width: 12.375rem;
  margin-left: 2.9375rem;
}
@media (min-width: 768px) {
  .dd-m-slider-banner__wrapper .dd-m-slider-banner__caption {
    margin-left: 6.125rem;
    max-width: 18.25rem;
  }
}
@media (min-width: 1024px) {
  .dd-m-slider-banner__wrapper .dd-m-slider-banner__caption {
    margin-left: 5.0625rem;
    max-width: 15.1875rem;
  }
}
.dd-m-slider-banner__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6875rem;
  height: 1.6875rem;
  background-color: #92ff00;
  color: #010f65;
  font-size: 0.5rem;
}
.dd-m-slider-banner__content {
  margin-bottom: 1.6875rem;
  padding: 6px 0;
  border-left: 17px solid #fff;
  line-height: 1rem;
}
@media (min-width: 768px) {
  .dd-m-slider-banner__content {
    padding: 7px 0;
    line-height: 1.375rem;
    border-left: 20px solid #fff;
  }
}
.dd-m-slider-banner__title, .dd-m-slider-banner__description {
  background-color: #fff;
  padding: 0.3125rem 0;
  display: inline;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .dd-m-slider-banner__title, .dd-m-slider-banner__description {
    padding: 0.25rem 0 0.375rem;
    font-size: 1.25rem;
    font-weight: 500;
  }
}
.dd-m-slider-banner__title span, .dd-m-slider-banner__description span {
  position: relative;
  left: -10px;
}
.dd-m-slider-banner app-button-anchor,
.dd-m-slider-banner app-button-back {
  display: block;
  margin-left: 0.4375rem;
}
@media (min-width: 768px) {
  .dd-m-slider-banner app-button-anchor,
.dd-m-slider-banner app-button-back {
    margin-left: 0.625rem;
  }
}
.dd-m-slider-banner app-button-anchor,
.dd-m-slider-banner app-button-back {
  display: block;
  margin-top: 1.625rem;
}

.slider-mosaic .slick-slider {
  margin-bottom: 1.6875rem;
}
@media (min-width: 768px) {
  .slider-mosaic .slick-slider {
    margin-bottom: 3.4375rem;
  }
}
@media (min-width: 1024px) {
  .slider-mosaic .slick-slider {
    margin-bottom: 2.3125rem;
  }
}
.slider-mosaic .slick-list {
  padding: 0 1.6875rem;
}
@media (min-width: 768px) {
  .slider-mosaic .slick-list {
    padding: 0 3.4375rem;
  }
}
@media (min-width: 1024px) {
  .slider-mosaic .slick-list {
    padding: 0 2.3125rem;
  }
}
@media (min-width: 1440px) {
  .slider-mosaic .slick-list {
    padding: 0;
  }
}
.slider-mosaic .slick-slide a {
  display: block;
  width: 100%;
  height: 100%;
}
.slider-mosaic .slick-slide app-card-mosaic-instagram {
  display: block !important;
}
.slider-mosaic .slick-slide:not(:last-child) {
  margin-right: 1.6875rem;
}
.slider-mosaic .slick-slide > div:first-child {
  height: 100%;
  margin-bottom: 1.6875rem;
}
@media (min-width: 1024px) {
  .slider-mosaic .carousel-mobile {
    display: none;
  }
}
.slider-mosaic .carousel-mobile .slider-mosaic-nav {
  display: flex;
}
@media (min-width: 1024px) {
  .slider-mosaic .carousel-mobile .slider-mosaic-nav {
    display: none;
  }
}
.slider-mosaic .carousel-mobile .slick-slide:nth-child(1n+2) > div:first-child app-card-mosaic-instagram {
  width: 6.75rem !important;
  height: 6.75rem !important;
}
@media (min-width: 768px) {
  .slider-mosaic .carousel-mobile .slick-slide:nth-child(1n+2) > div:first-child app-card-mosaic-instagram {
    width: 10.3125rem !important;
    height: 10.3125rem !important;
  }
}
.slider-mosaic .carousel-mobile .slick-slide:nth-child(1n+2) > div:last-child app-card-mosaic-instagram {
  width: 13.5rem !important;
  height: 13.5rem !important;
}
@media (min-width: 768px) {
  .slider-mosaic .carousel-mobile .slick-slide:nth-child(1n+2) > div:last-child app-card-mosaic-instagram {
    width: 16.8125rem !important;
    height: 16.8125rem !important;
  }
}
.slider-mosaic .carousel-mobile .slick-slide:nth-child(1n+2):nth-child(odd) > div:first-child app-card-mosaic-instagram {
  margin-left: auto;
}
.slider-mosaic .carousel-mobile .slick-slide:nth-child(3n+1) > div:first-child app-card-mosaic-instagram {
  width: 13.5rem !important;
  height: 13.5rem !important;
}
@media (min-width: 768px) {
  .slider-mosaic .carousel-mobile .slick-slide:nth-child(3n+1) > div:first-child app-card-mosaic-instagram {
    width: 16.8125rem !important;
    height: 16.8125rem !important;
  }
}
.slider-mosaic .carousel-mobile .slick-slide:nth-child(3n+1) > div:last-child app-card-mosaic-instagram {
  width: 6.75rem !important;
  height: 6.75rem !important;
}
@media (min-width: 768px) {
  .slider-mosaic .carousel-mobile .slick-slide:nth-child(3n+1) > div:last-child app-card-mosaic-instagram {
    width: 10.3125rem !important;
    height: 10.3125rem !important;
  }
}
.slider-mosaic .carousel-desktop {
  display: none;
}
@media (min-width: 1024px) {
  .slider-mosaic .carousel-desktop {
    display: block;
  }
}
@media (min-width: 1440px) {
  .slider-mosaic .carousel-desktop {
    max-width: 85.375rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.slider-mosaic .carousel-desktop .slider-mosaic-nav {
  display: none;
}
@media (min-width: 1024px) {
  .slider-mosaic .carousel-desktop .slider-mosaic-nav {
    display: flex;
  }
}
.slider-mosaic .carousel-desktop app-card-mosaic-instagram {
  width: 13.625rem !important;
  height: 13.625rem !important;
}
@media (min-width: 1440px) {
  .slider-mosaic .carousel-desktop app-card-mosaic-instagram {
    width: 20.125rem !important;
    height: 20.125rem !important;
  }
}

.slider-timeline .swiper-wrapper {
  padding-left: 1.6875rem;
}
@media (min-width: 768px) {
  .slider-timeline .swiper-wrapper {
    padding-left: 3.4375rem;
  }
}
@media (min-width: 1024px) {
  .slider-timeline .swiper-wrapper {
    padding-left: 2.3125rem;
  }
}
@media (min-width: 1440px) {
  .slider-timeline .swiper-wrapper {
    padding-left: 0;
  }
}
.slider-timeline .swiper-slide:first-child .card-title,
.slider-timeline .swiper-slide:first-child .card-description {
  padding-left: 1.6875rem;
}
.slider-timeline .swiper-slide:first-child .dot {
  transform: translateX(27px);
}

.slider-bullet-energized .swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
  opacity: 1 !important;
  background-color: transparent !important;
  border: 1px solid #010f65;
  vertical-align: middle;
  margin: 0 7px !important;
}
.slider-bullet-energized .swiper-pagination-bullet-active {
  background-color: #91ff00 !important;
}

.slider-header {
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .slider-header {
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1024px) {
  .slider-header {
    margin-bottom: 1.25rem;
  }
}
.slider-header__title {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.6875rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  font-weight: 500;
}
@media (min-width: 768px) {
  .slider-header__title {
    font-size: 1rem;
    line-height: 1.1375rem;
  }
}
.slider-header__title strong {
  font-weight: 600;
}
.slider-header__description {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 300;
}
.slider .swiper-wrapper > * {
  height: initial !important;
}

.banner-hero app-card-outer-hero {
  display: block;
  height: 100%;
}
@media (max-width: 1023px) {
  .banner-hero .swiper-container-autoheight .swiper-wrapper {
    height: auto !important;
  }
}

.banner-hero .swiper-pagination-bullets {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .banner-hero .swiper-pagination-bullets {
    display: none;
  }
}
.banner-hero .swiper-pagination-bullets .swiper-pagination-handle {
  margin: 0 0.875rem !important;
  padding: 0 !important;
}
.banner-hero .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 !important;
  width: 0.375rem !important;
  height: 0.375rem !important;
  border: 0 !important;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #010f65;
  opacity: 1;
}
.banner-hero .swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: #010f65;
}

.banner-highlight .swiper-pagination {
  bottom: unset;
  position: relative;
  margin-top: 20px;
}
.banner-highlight .swiper-pagination-bullets {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-highlight .swiper-pagination-bullets .swiper-pagination-handle {
  margin: 0 0.875rem !important;
  padding: 0 !important;
}
.banner-highlight .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 !important;
  width: 0.375rem !important;
  height: 0.375rem !important;
  border: 0 !important;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #010f65;
  opacity: 1;
}
.banner-highlight .swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: #010f65;
}

.post-info:first-child .post-info__button {
  display: none;
}
.post-info .dd-m-post-content {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 100;
  margin: 0;
}
.post-info .dd-m-post-content strong {
  font-weight: 500;
}
.post-info .dd-m-post-content a {
  color: #010f65;
  text-decoration: underline;
}
.post-info .dd-m-post-content a:hover {
  color: #010b4c;
  text-decoration: none;
}
.post-info .dd-m-post-content a:visited {
  color: rgba(1, 15, 101, 0.6);
}
.post-info .dd-m-post-content p:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .post-info .dd-m-post-content p:not(:last-child) {
    margin-bottom: 1.125rem;
  }
}
.post-info .dd-m-post-content h2 {
  margin: 0;
  margin-bottom: 0.875rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
}
.post-info .dd-m-post-content h3 {
  margin: 0;
  margin-left: 1.6875rem;
  margin-bottom: 1.6875rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;
}
@media (min-width: 768px) {
  .post-info .dd-m-post-content h3 {
    margin-left: 3.4375rem;
    font-size: 1.375rem;
    line-height: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .post-info .dd-m-post-content h3 {
    margin-left: 2.3125rem;
  }
}
.post-info .dd-m-post-content h4 {
  margin: 0;
  margin-bottom: 1.6875rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;
}
@media (min-width: 768px) {
  .post-info .dd-m-post-content h4 {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
}
.post-info .dd-m-post-content img {
  display: block;
  width: 100%;
  height: auto;
}
.post-info .dd-m-post-content blockquote {
  margin: 1.6875rem 0;
  padding: 0;
  padding-left: 0.875rem;
  border-left: 0.125rem solid #010f65;
}
@media (min-width: 768px) {
  .post-info .dd-m-post-content blockquote {
    margin: 3.4375rem 0;
    padding-left: 1.6875rem;
  }
}
@media (min-width: 1024px) {
  .post-info .dd-m-post-content blockquote {
    margin: 2.3125rem 0;
    padding-left: 1.125rem;
  }
}
.post-info .dd-m-post-content blockquote p {
  line-height: 1.5;
}
.post-info .dd-m-post-content blockquote cite {
  display: block;
  margin-top: 0.875rem;
}
@media (min-width: 768px) {
  .post-info .dd-m-post-content blockquote cite {
    margin-top: 1.6875rem;
  }
}
@media (min-width: 1024px) {
  .post-info .dd-m-post-content blockquote cite {
    margin-top: 1.125rem;
  }
}
.post-info .dd-m-post-content.more {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.post-info__button {
  display: inline-block;
  margin-top: 20px;
}

.dd-m-post {
  margin-bottom: 1.6875rem;
}
@media (min-width: 768px) {
  .dd-m-post {
    margin-bottom: 3.4375rem;
  }
}
@media (min-width: 1024px) {
  .dd-m-post {
    margin-bottom: 2.3125rem;
  }
}
.dd-m-post-header h1 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}
@media (min-width: 768px) {
  .dd-m-post-header h1 {
    margin-bottom: 1.1875rem;
    font-size: 1rem;
    line-height: 1.125rem;
    letter-spacing: 0.0875rem;
  }
}
.dd-m-post-content {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 300;
  word-break: break-word;
}
@media (min-width: 768px) {
  .dd-m-post-content {
    padding-right: 0.125rem;
  }
}
@media (min-width: 1024px) {
  .dd-m-post-content {
    padding-right: 0;
  }
}
.dd-m-post-content > div > h2 {
  margin: 0 !important;
  margin-bottom: 0.875rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}
.dd-m-post-content > div > h3 {
  margin: 0 !important;
  margin-left: 1.6875rem !important;
  margin-bottom: 1.6875rem !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  line-height: 1.25rem !important;
}
@media (min-width: 768px) {
  .dd-m-post-content > div > h3 {
    margin-left: 3.4375rem !important;
    font-size: 1.375rem !important;
    line-height: 1.5rem !important;
  }
}
@media (min-width: 1024px) {
  .dd-m-post-content > div > h3 {
    margin-left: 2.3125rem !important;
  }
}
.dd-m-post-content > div > h4 {
  margin: 0 !important;
  margin-bottom: 1.6875rem !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  line-height: 1.25rem !important;
}
@media (min-width: 768px) {
  .dd-m-post-content > div > h4 {
    font-size: 1.25rem !important;
    line-height: 1.375rem !important;
  }
}
.dd-m-post-content > div > p {
  margin: 0 !important;
  font-size: 0.875rem !important;
  line-height: 1rem !important;
}
.dd-m-post-content > div > p:not(:last-child) {
  margin-bottom: 1rem !important;
}
.dd-m-post-content > div > p a {
  color: #010f65 !important;
  text-decoration: underline;
}
.dd-m-post-content > div > p a:hover {
  color: #010b4c;
  text-decoration: none;
}
.dd-m-post-content > div > p a:visited {
  color: rgba(1, 15, 101, 0.6);
}
.dd-m-post-content > div img {
  display: block;
  width: 100%;
  height: auto;
}
.dd-m-post-content > div > blockquote {
  margin: 1.6875rem 0;
  padding: 0;
  padding-left: 0.875rem;
  border-left: 0.125rem solid #010f65;
}
@media (min-width: 768px) {
  .dd-m-post-content > div > blockquote {
    margin: 3.4375rem 0;
    padding-left: 1.6875rem;
  }
}
@media (min-width: 1024px) {
  .dd-m-post-content > div > blockquote {
    margin: 2.3125rem 0;
    padding-left: 1.125rem;
  }
}
.dd-m-post-content > div > blockquote p {
  line-height: 1.5;
}
.dd-m-post-content > div > blockquote cite {
  display: block;
  margin-top: 0.875rem;
}
@media (min-width: 768px) {
  .dd-m-post-content > div > blockquote cite {
    margin-top: 1.6875rem;
  }
}
@media (min-width: 1024px) {
  .dd-m-post-content > div > blockquote cite {
    margin-top: 1.125rem;
  }
}
.dd-m-post-content iframe {
  width: 100%;
  height: 14.1875rem;
  margin-top: 0.625rem;
}
@media (min-width: 768px) {
  .dd-m-post-content iframe {
    height: 20.625rem;
  }
}
.dd-m-post-content .slider-list {
  max-width: 100% !important;
  margin-bottom: 0;
}
.dd-m-post-content .slider-header {
  max-width: 100% !important;
}
.dd-m-post-content .slider-header__title {
  margin: 0 !important;
}
.dd-m-post-content .table-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  margin: 1.25rem 0;
}
.dd-m-post-content table {
  border: 0 solid transparent;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  min-width: 59.375rem;
  text-align: left;
  border-top: 1px solid #010f65;
  border-bottom: 1px solid #010f65;
}
@media (min-width: 768px) {
  .dd-m-post-content table {
    min-width: 100%;
  }
}
.dd-m-post-content table tr {
  border-bottom: 1px solid #010f65;
}
.dd-m-post-content table td,
.dd-m-post-content table th {
  padding: 1rem 0;
  vertical-align: initial;
}
.dd-m-post-content table tr:first-child td {
  font-weight: 400;
}
.dd-m-post-content table td {
  font-weight: 300;
  padding-right: 1.6875rem;
  font-size: 1rem;
  line-height: 1.125rem;
}
.dd-m-post-content table p {
  font-size: 1rem;
  line-height: 1.125rem;
}
.dd-m-post-content table a {
  color: inherit;
  text-decoration: underline;
}
.dd-m-post-content dt {
  font-weight: 600;
}
.dd-m-post-content dd {
  margin: 0 0 1rem;
}
.dd-m-post-content ul,
.dd-m-post-content ol {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1.5;
}
.dd-m-post-content ul ul,
.dd-m-post-content ul ol,
.dd-m-post-content ol ul,
.dd-m-post-content ol ol {
  margin-left: 0.875rem;
}
.dd-m-post-content ul ul ul,
.dd-m-post-content ul ul ol,
.dd-m-post-content ul ol ul,
.dd-m-post-content ul ol ol,
.dd-m-post-content ol ul ul,
.dd-m-post-content ol ul ol,
.dd-m-post-content ol ol ul,
.dd-m-post-content ol ol ol {
  margin-left: 0.875rem;
}
.dd-m-post-content.more p {
  display: none;
}
.dd-m-post-content.more p:first-child {
  display: block;
}
.dd-m-post-content.more p:first-child:after {
  content: "[…]";
  font-weight: 500;
}
.dd-m-post-content .video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
.dd-m-post-content .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.dd-m-post-figure {
  margin: 1.6875rem auto;
}
@media (min-width: 768px) {
  .dd-m-post-figure {
    margin: 1.5rem auto 1.875rem;
  }
}
@media (min-width: 1024px) {
  .dd-m-post-figure {
    margin: 2.3125rem auto;
  }
}
.dd-m-post-figure:last-child {
  margin-bottom: 0;
}
.dd-m-post-figure img {
  width: 100%;
  height: auto;
  display: block;
}

.dd-m-list-cards {
  padding: 1.6875rem 0;
}
@media (min-width: 768px) {
  .dd-m-list-cards {
    padding: 3.4375rem 0;
  }
}
@media (min-width: 1024px) {
  .dd-m-list-cards {
    padding: 2.3125rem 0;
  }
}
@media (min-width: 1440px) {
  .dd-m-list-cards {
    padding: 3.25rem 0;
  }
}
.dd-m-list-cards-container {
  display: flex;
  flex-wrap: wrap;
}
.dd-m-list-cards.stable {
  position: relative;
}
.dd-m-list-cards.stable:before, .dd-m-list-cards.stable:after {
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  display: block;
  width: 120rem;
  height: 100%;
  background-color: #f9f9f6;
}
.dd-m-list-cards.stable:before {
  left: 50%;
}
.dd-m-list-cards.stable:after {
  right: 50%;
}
.dd-m-list-cards .card {
  height: inherit;
}
.dd-m-list-cards .card-content {
  padding: 14px;
}
@media (min-width: 768px) {
  .dd-m-list-cards .card-content {
    padding: 27px;
  }
}
@media (min-width: 1024px) {
  .dd-m-list-cards .card-content {
    padding: 54px;
  }
}
.dd-m-list-cards .card-content__columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
}
@media (min-width: 768px) {
  .dd-m-list-cards .card-content__columns {
    grid-gap: 27px;
  }
}
.dd-m-list-cards .card-content__columns p {
  margin: 0;
}
@media (min-width: 1024px) {
  .dd-m-list-cards .card-content__columns p {
    font-size: 16px;
    line-height: 18px;
  }
}
.dd-m-list-cards .card-content__columns strong {
  display: block;
  font-weight: 500;
  min-height: 32px;
  margin-bottom: 6px;
}
@media (min-width: 1024px) {
  .dd-m-list-cards .card-content__columns strong {
    font-size: 16px;
    min-height: unset;
    margin-bottom: 14px;
  }
}
.dd-m-list-cards .card-title {
  flex: unset;
}
.dd-m-list-cards .card:nth-child(1), .dd-m-list-cards .card:nth-child(2) {
  width: calc(50% - 7px);
}
@media (min-width: 768px) {
  .dd-m-list-cards .card:nth-child(1), .dd-m-list-cards .card:nth-child(2) {
    width: calc(50% - 13.5px);
  }
}
.dd-m-list-cards .card:nth-child(2) {
  margin-left: 14px;
}
@media (min-width: 768px) {
  .dd-m-list-cards .card:nth-child(2) {
    margin-left: 27px;
  }
}
.dd-m-list-cards .card:nth-child(3) {
  margin-top: 14px;
}
@media (min-width: 768px) {
  .dd-m-list-cards .card:nth-child(3) {
    margin-top: 20px;
  }
}
@media (min-width: 1024px) {
  .dd-m-list-cards .card:nth-child(3) {
    margin-top: 27px;
  }
}

.list-products {
  width: 100%;
}
.list-products ul {
  margin: -0.4375rem;
  margin-top: -1.125rem;
}
@media (min-width: 768px) {
  .list-products ul {
    margin: -0.8125rem;
    margin-top: -1.125rem;
  }
}
@media (min-width: 1024px) {
  .list-products ul {
    margin-top: -1.125rem;
  }
}
.list-products li {
  width: 100%;
  margin: 0.8125rem 0.4375rem;
}
@media (min-width: 320px) {
  .list-products li {
    width: calc(50% - 14px);
  }
}
@media (min-width: 768px) {
  .list-products li {
    width: calc(50% - 26px);
    margin: 0.8125rem;
  }
}
@media (min-width: 1024px) {
  .list-products li {
    width: calc(33.333% - 26px);
    margin: 0.8125rem;
  }
}
.list-products li > * {
  display: block;
  width: 100%;
  height: 100%;
}

.list-stores {
  width: 100%;
}
.list-stores .list {
  margin-top: 0 !important;
}
.list-stores ul {
  margin: -0.4375rem;
}
@media (min-width: 1024px) {
  .list-stores ul {
    margin: -0.75rem;
  }
}
.list-stores li {
  width: 100%;
  margin: 0.8125rem 0.4375rem;
}
@media (min-width: 320px) {
  .list-stores li {
    width: calc(50% - 14px);
  }
}
@media (min-width: 768px) {
  .list-stores li {
    width: calc(25% - 14px);
    margin: 0.4375rem;
  }
}
@media (min-width: 1024px) {
  .list-stores li {
    width: calc(20% - 26px);
    margin: 0.8125rem;
  }
}
.list-stores li > * {
  display: block;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .list.margin {
    margin-top: 2.1875rem;
  }
}
.list-header {
  margin-bottom: 1.25rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .list-header {
    margin-bottom: 1.625rem;
  }
}
.list-header__title, .list-empty-message, .list-header__title--md {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.6875rem;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: 0.04375rem;
  text-transform: uppercase;
  font-weight: 600;
}
@media (min-width: 768px) {
  .list-header__title, .list-empty-message, .list-header__title--md {
    margin-left: 0;
    font-size: 1rem;
    line-height: 1.125rem;
  }
}
@media (min-width: 1024px) {
  .list-header__title, .list-empty-message, .list-header__title--md {
    margin-left: 1.6875rem;
  }
}
.list-header__title strong, .list-empty-message strong, .list-header__title--md strong {
  font-weight: 600;
}
.list-header__title--md {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-top: 1.6875rem;
}
@media (min-width: 768px) {
  .list-header__title--md {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
.list-content.is-loading {
  position: relative;
}
.list-content.is-loading app-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%);
}
.list-content.is-loading ul {
  opacity: 0.2;
}
.list-content app-spinner {
  display: none;
}
.list-content ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.list-empty-message {
  margin: 40px auto;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .list-empty-message {
    margin-left: 0;
    margin: 40px auto;
    font-size: 0.875rem;
    line-height: 1rem;
  }
}

.mat-dialog-container {
  border-radius: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}

.cdk-overlay-backdrop.onboarding {
  background-color: rgba(249, 249, 246, 0.8);
  backdrop-filter: blur(5px);
  transition: 0.2s all ease;
}

.cdk-overlay-pane {
  width: 100% !important;
  max-width: calc(100% - 54px) !important;
}
@media (min-width: 768px) {
  .cdk-overlay-pane {
    max-width: calc(100% - 110px) !important;
  }
}
@media (min-width: 1024px) {
  .cdk-overlay-pane {
    max-width: calc(100% - 220px) !important;
  }
}
@media (min-width: 1024px) {
  .cdk-overlay-pane.onboarding {
    max-width: 54.75rem !important;
  }
}
.cdk-overlay-pane.onboarding .mat-dialog-container {
  box-shadow: none !important;
}

.dd-m-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  color: #010f65;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (min-width: 802px) {
  .dd-m-modal--has-max {
    max-width: 50.125rem !important;
  }
}
.dd-m-modal--bg {
  height: calc(100vh - 27px);
}
@media (min-width: 768px) {
  .dd-m-modal--bg {
    height: calc(100vh - 55px);
  }
}
@media (min-width: 1024px) {
  .dd-m-modal--bg {
    height: calc(100vh - 74px);
  }
}
.dd-m-modal--bg .mat-dialog-container {
  background-color: #f9f9f6;
}
.dd-m-modal-title {
  margin: 0;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  line-height: 1.1375rem;
  font-weight: 500;
}
@media (min-width: 768px) {
  .dd-m-modal-title {
    margin-bottom: 1.6875rem;
    font-size: 1.25rem;
  }
}
.dd-m-modal-subtitle {
  margin: 0;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  line-height: 1.1375rem;
  font-weight: 400;
}
@media (min-width: 768px) {
  .dd-m-modal-subtitle {
    margin-bottom: 1.6875rem;
    font-size: 1.25rem;
  }
}
.dd-m-modal-nav__item {
  color: #010f65;
}
.dd-m-modal-nav__item.active {
  font-weight: 500;
  text-decoration: underline;
}
.dd-m-modal-nav__item:not(:last-child) {
  margin-right: 0.1875rem;
}
.dd-m-modal-nav__item:not(:last-child):after {
  content: "|";
  display: inline-block;
  margin-left: 0.1875rem;
}
.dd-m-modal__close {
  width: 2.875rem;
  height: 2.875rem;
  flex: 0 0 2.875rem;
  margin-left: auto;
  margin-bottom: 0.5rem;
  background-color: #010f65;
  color: #fff;
}
@media (min-width: 1024px) {
  .dd-m-modal__close {
    margin-bottom: 1.6875rem;
  }
}
.dd-m-modal-content {
  margin: 0 1.6875rem 1.6875rem;
}
@media (min-width: 768px) {
  .dd-m-modal-content {
    margin: 0 3.4375rem 3.4375rem;
  }
}
@media (min-width: 1024px) {
  .dd-m-modal-content {
    margin: 0 2.3125rem 2.3125rem;
  }
}
.dd-m-modal-nav {
  position: relative;
  height: 2.5rem;
}
@media (min-width: 768px) {
  .dd-m-modal-nav {
    height: 2.875rem;
  }
}
.dd-m-modal .dd-m-form {
  margin-bottom: 1.6875rem;
}
.dd-m-modal .dd-m-form-control:not(:last-child) {
  margin-bottom: 0;
}
.dd-m-modal .dd-m-form-control__wrap {
  margin-bottom: 0.625rem;
}
.dd-m-modal .dd-m-slider-banner-prev,
.dd-m-modal .dd-m-slider-banner-next {
  background-color: #010f65 !important;
}
.dd-m-modal-gallery {
  height: calc(100vh - 27px);
}
@media (min-width: 768px) {
  .dd-m-modal-gallery {
    height: calc(100vh - 55px);
  }
}
@media (min-width: 1024px) {
  .dd-m-modal-gallery {
    height: calc(100vh - 74px);
  }
}
.dd-m-modal-gallery .dd-m-modal-content {
  margin: 0;
  flex: 1;
}
.dd-m-modal-gallery .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.dd-m-modal-gallery img {
  width: 100%;
}
@media (min-width: 1024px) {
  .dd-m-modal-gallery img {
    width: auto;
  }
}
.dd-m-modal--full.cdk-overlay-pane {
  max-width: 100% !important;
}

@media screen and (max-width: 767px) {
  .full-screen-modal {
    max-width: unset !important;
    width: 100%;
    height: 100%;
  }
}
.full-screen-modal.has-max {
  max-width: 864px !important;
}
.full-screen-modal .mat-dialog-container {
  max-width: 100vw;
  max-height: 100vh;
  height: 100%;
  width: 100%;
}
.full-screen-modal .mat-dialog-container .mat-dialog-content {
  max-height: unset !important;
}

@keyframes slide {
  100% {
    transform: translateX(0);
  }
}
.globalnav {
  transform: translateX(-100%);
  animation: slide 0.1s forwards;
  animation-delay: 0.1s;
  width: 100%;
  max-width: 100% !important;
  height: 100%;
  background-color: #92ff00;
}
@media (min-width: 1024px) {
  .globalnav {
    width: 50%;
    max-width: 23.5rem !important;
  }
}
.globalnav mat-dialog-container {
  background-color: unset;
}
.globalnav mat-dialog-content {
  flex: 1;
  max-height: 100%;
}
.globalnav .mat-dialog-container {
  padding: 0;
  overflow-x: hidden;
  border-radius: 0;
}
.globalnav app-globalnav {
  display: block;
  height: 100%;
}

.cdk-overlay-pane.login {
  width: 20.125rem !important;
}
@media (min-width: 768px) {
  .cdk-overlay-pane.login {
    width: 23.5rem !important;
  }
}

.cdk-overlay-pane.has-max-height .mat-dialog-content {
  max-height: 100%;
}

.cdk-overlay-backdrop.login {
  background-color: rgba(10, 42, 82, 0.92);
}

.cdk-overlay-pane.modalFav {
  width: 20.125rem !important;
}
@media (min-width: 768px) {
  .cdk-overlay-pane.modalFav {
    width: 23.5rem !important;
  }
}
@media (min-width: 1024px) {
  .cdk-overlay-pane.modalFav {
    width: 39.0625rem !important;
  }
}

.cdk-overlay-backdrop.modalFav {
  background-color: rgba(146, 255, 0, 0.92);
}

.cdk-overlay-pane.complete-registration {
  z-index: 1002 !important;
  width: 19.125rem !important;
}
@media (min-width: 768px) {
  .cdk-overlay-pane.complete-registration {
    width: 31.125rem !important;
  }
}
@media (min-width: 1024px) {
  .cdk-overlay-pane.complete-registration:not(.has-max) {
    width: 34.5rem !important;
  }
}

.cdk-overlay-backdrop.complete-registration {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-energized {
  background-color: rgba(145, 255, 0, 0.8);
}

.shadow-thin .mat-dialog-container {
  box-shadow: 0 0 10px 20px rgba(0, 0, 0, 0.02);
}

.dd-m-modal-small {
  max-width: 306px !important;
}

.dd-l-wrapper, .dd-l-wrapper-inner, .slider-cards .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .slider-header, .dd-m-slider-banner.default .slider-header {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.dd-l-wrapper-inner, .slider-cards .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .slider-header, .dd-m-slider-banner.default .slider-header {
  max-width: calc(100% - 54px);
}
@media screen and (min-width: 768px) {
  .dd-l-wrapper-inner, .slider-cards .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .slider-header, .dd-m-slider-banner.default .slider-header {
    max-width: calc(100% - 109px);
  }
}
@media screen and (min-width: 1024px) {
  .dd-l-wrapper-inner, .slider-cards .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .slider-header, .dd-m-slider-banner.default .slider-header {
    max-width: calc(100% - 74px);
  }
}
@media screen and (min-width: 1440px) {
  .dd-l-wrapper-inner, .slider-cards .swiper-container, .dd-m-slider-banner.default .dd-l-wrapper, .dd-m-slider-banner.default .dd-l-wrapper-inner, .dd-m-slider-banner.default .slider-cards .swiper-container, .slider-cards .dd-m-slider-banner.default .swiper-container, .slider-mosaic .swiper-container, .dd-m-slider-banner.default .slider-mosaic .swiper-container, .slider-mosaic .dd-m-slider-banner.default .swiper-container, .slider-header, .dd-m-slider-banner.default .slider-header {
    max-width: 1366px;
  }
}

.dd-l-full {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.dd-l-page-calculator app-header,
.dd-l-page-calculator app-footer {
  display: none;
}
.dd-l-page-calculator main.main {
  margin-top: 0;
}