.dd-m-slider-banner {
  figure {
    margin: 0;
  }

  img {
    width: auto;
    height: auto;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }

  + p > br {
    display: none;
  }

  &.default {
    position: relative;
    margin-top: rem(27px);
    margin-bottom: rem(25px);

    .dd-l-wrapper {
      @extend .dd-l-wrapper-inner;
    }

    .dd-m-slider-banner__caption {
      margin-left: rem(21px);

      @media (min-width: $tablet) {
        margin-left: rem(44px);
      }

      @media (min-width: $desktop-hd) {
        margin-left: rem(82px);
      }
    }
  }

  &.full {
    top: 0;
    left: 0;
    right: 0;

    figure {
      height: 100%;
    }

    img {
      width: auto !important;
      height: auto !important;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
      margin: 0 !important;
    }
  }

  .swiper-slide {
    margin: 0;
    overflow: hidden !important;

    img {
      display: block;
      margin: 0;
      width: auto !important;
      height: auto !important;
    }
  }

  &-next,
  &-prev {
    align-items: center !important;
    justify-items: center !important;
    position: absolute !important;
    bottom: 0 !important;
    top: inherit !important;
    z-index: 2 !important;
    width: rem(40px) !important;
    height: rem(40px) !important;
    background-color: rgba($color-positive-dark, 0.8) !important;
    color: $white !important;
    font-size: rem(19px) !important;
    transition: 0.2s all ease;

    @media (min-width: $tablet) {
      width: rem(55px) !important;
      height: rem(46px) !important;
    }

    &:hover {
      background-color: $color-positive-dark;
    }

    &::after {
      content: none !important;
    }

    i {
      margin: auto;
    }
  }

  &-next {
    right: 0 !important;
    left: inherit !important;
    padding-right: rem(6px) !important;
  }

  &-prev {
    padding-left: rem(6px) !important;
    right: rem(40px) !important;
    left: inherit !important;

    @media (min-width: $tablet) {
      right: rem(55px) !important;
    }
  }

  &__wrapper {
    max-width: 1366px;
    width: 100%;
    position: absolute !important;
    top: rem(105px);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    @media (min-width: $tablet) {
      top: rem(111px);
    }

    @media (min-width: $desktop) {
      top: rem(93px);
    }

    &.only-title {
      app-button-anchor,
      app-button-back {
        display: none;
      }

      @media (max-width: $tablet - 1px) {
        top: rem(160px);
      }
    }

    &.only-image {
      .dd-m-slider-banner__caption {
        display: none;
      }
    }

    .dd-m-slider-banner__caption {
      max-width: rem(198px);
      margin-left: rem(47px);

      @media (min-width: $tablet) {
        margin-left: rem(98px);
        max-width: rem(292px);
      }

      @media (min-width: $desktop) {
        margin-left: rem(81px);
        max-width: rem(243px);
      }
    }
  }

  &__arrow {
    @extend .reset-button;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(27px);
    height: rem(27px);
    background-color: $color-energized-light;
    color: $color-positive-dark;
    font-size: rem(8px);
  }

  &__content {
    margin-bottom: rem(27px);
    padding: 6px 0;
    border-left: 17px solid $white;
    line-height: rem(16px);

    @media (min-width: $tablet) {
      padding: 7px 0;
      line-height: rem(22px);
      border-left: 20px solid $white;
    }
  }

  &__title,
  &__description {
    background-color: $white;
    padding: rem(5px) 0;
    display: inline;
    margin: 0;
    font-size: rem(14px);
    font-weight: 400;

    @media (min-width: $tablet) {
      padding: rem(4px) 0 rem(6px);
      font-size: rem(20px);
      font-weight: 500;
    }

    span {
      position: relative;
      left: -10px;
    }
  }

  app-button-anchor,
  app-button-back {
    display: block;
    margin-left: rem(7px);

    @media (min-width: $tablet) {
      margin-left: rem(10px);
    }
  }

  app-button-anchor,
  app-button-back {
    display: block;
    margin-top: rem(26px);
  }
}
