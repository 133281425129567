@import "./cards";
@import "./gallery";
@import "./banner";
@import "./sync";
@import "./mosaic";
@import "./timeline";

.slider-bullet-energized .swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
  opacity: 1 !important;

  background-color: transparent !important;
  border: 1px solid $brand;

  vertical-align: middle;
  margin: 0 7px !important;

  &-active {
    background-color: $color-energized-light-2 !important;
  }
}

.slider {
  &-header {
    @extend .dd-l-wrapper-inner;
    margin-bottom: rem(16px);
    position: relative;
    z-index: 2;

    @media (min-width: $tablet) {
      margin-bottom: rem(20px);
    }

    @media (min-width: $desktop) {
      margin-bottom: rem(20px);
    }

    &__title {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: rem(27px);
      font-size: rem(14px);
      line-height: rem(14px);
      letter-spacing: rem(1px);
      text-transform: uppercase;
      font-weight: 500;

      @media (min-width: $tablet) {
        font-size: rem(16px);
        line-height: rem(18.2px);
      }

      strong {
        font-weight: 600;
      }
    }

    &__description {
      margin: 0;
      font-size: rem(14px);
      line-height: rem(16px);
      font-weight: 300;
    }
  }

  .swiper-wrapper {
    > * {
      height: initial !important;
    }
  }
}

.banner-hero {
  app-card-outer-hero {
    display: block;
    height: 100%;
  }

  .swiper-container-autoheight .swiper-wrapper {
    @media (max-width: $desktop - 1px) {
      height: auto !important;
    }
  }
}

.banner-hero {
  .swiper-pagination-bullets {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $tablet) {
      display: none;
    }

    .swiper-pagination-handle {
      margin: 0 rem(14px) !important;
      padding: 0 !important;
    }

    .swiper-pagination-bullet {
      margin: 0 !important;
      width: rem(6px) !important;
      height: rem(6px) !important;
      border: 0 !important;
      background-color: transparent;
      box-shadow: inset 0 0 0 1px $color-positive-dark;
      opacity: 1;

      &-active {
        background-color: $color-positive-dark;
      }
    }
  }
}

.banner-highlight {
  .swiper-pagination {
    bottom: unset;
    position: relative;
    margin-top: 20px;
  }

  .swiper-pagination-bullets {
    display: flex;
    align-items: center;
    justify-content: center;

    .swiper-pagination-handle {
      margin: 0 rem(14px) !important;
      padding: 0 !important;
    }

    .swiper-pagination-bullet {
      margin: 0 !important;
      width: rem(6px) !important;
      height: rem(6px) !important;
      border: 0 !important;
      background-color: transparent;
      box-shadow: inset 0 0 0 1px $color-positive-dark;
      opacity: 1;

      &-active {
        background-color: $color-positive-dark;
      }
    }
  }
}
