$brand: #010f65;
$brand-dark: #000d4c;
$facebook: #3e99ef;
$facebook-dark: #3b5998;
$google: #eb4031;
$google-dark: #dd4b39;

$color-assertive: #eb4031;

$color-positive-black: darken($brand, 5%);
$color-positive-dark: $brand;
$color-positive-strong: #000d57;
$color-positive-medium: #0a2a52;
$color-positive-normal: #02385a;
$color-positive-light: #61e7fc;
$color-energized-light: #92ff00;
$color-energized-light-2: #91ff00;
$color-energized-light-3: #f9b20a;

$color-stable-light: #f9f9f6;

$color-warning: #eb4031;

$white: #fff;
$white-light: #fffff0;

$color-calcium-primary: #57519b;
$color-calcium-primary-shade: #423c82;
$color-calcium-primary-tint: #6d66bc;
$color-calcium-secondary: #ffb90a;
$color-calcium-secondary-shade: #da9f0b;
$color-calcium-secondary-tint: #f7c23b;
